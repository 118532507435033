import React, { useState, useEffect } from "react"; // Import React and useState
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import paymentmethodImg from "../assets/images/payment-method.png";
import "react-phone-input-2/lib/style.css";
import cornerupRight from "../assets/images/corner-up-right.svg";
import EarlyCheckinImg from "../assets/images/early-check.svg";
import { Link } from "react-router-dom";

import Moment from "moment";

import HttpStatus from "./../helpers/status.enum";
import { useSelector, useDispatch } from "react-redux";
import { checkInActions } from "./../store/checkin.slice";
import { onboardingActions } from "../store/onboarding.slice";
import SpinnerLoader from "../components/SpinnerLoader";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { commonActions } from "../store/common.slice";
import { earlyCheckin } from "../store/earlyCheckin.slice";
import { paymentActions } from "./../store/payment.slice";
import EarlyCheckoutForm from "../components/EarlyCheckoutForm";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { commonRenderTextWithLinks } from "../config/functions";
import UpgradeItems from "../components/UpgradeItems";

// Changed by Gautam [Reason: ]
const stripePromiseForEarlyCheckin = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const encryKey = process.env.REACT_APP_STRIPE_ENCRYPT_KEY; // Must be 16 bytes for AES-128

function Reservationinfo() {
  const { listingSlug } = useParams();
  const [showcheckinEarlyModal, setShowcheckinEarlyModal] = useState(false);
  const [pageLoaderModal, setPageLoaderModal] = useState(true);

  const dispatch = useDispatch();
  const [records, setData] = useState(null);
  const [listingCms, setListingCms] = useState(null);
  const [listingLocals, setListingLocals] = useState(null);
  const [itemsList, setItemsList] = useState(null);
  const [listingHost, setListingHost] = useState(null);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiver, setIsWaiver] = useState(0);
  const [isDataEnabled, setIsDataEnabled] = useState(false);
  const [isTodayCheckedOut, setIsTodayCheckedOut] = useState(false);
  const [agreementItems, setAgreementList] = useState(false);
  const [cmsContent, setCmsContent] = useState(null);

  const [isAdShow, setIsAdShow] = useState(null);
  const [isPurchased, setIsPurchased] = useState(null);
  const [isCtaShow, setIsCtaShow] = useState(null);
  const [cardTitle, setCardTitle] = useState(null);
  const [cardBody, setCardBody] = useState(null);
  const [timeInterval, setTimeInterval] = useState([]);
  const [firstInterval, setFirstIntervalValue] = useState("");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("");

  const [isIntentCreate, setIsIntentCreate] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [isIntentModelShow, setIsIntentModelShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [paymentSlug, setPaymentSlug] = useState("");
  const [newCheckinTime, setNewCheckinTime] = useState("");
  const [isStateRefresh, setIsStateRefresh] = useState(false);
  const [isDesabled, setIsDesabled] = useState(false);
  const [isFreeCheckout, setIsFreeCheckout] = useState(false);
  const [needToEmailName, setNeedToEmailName] = useState(false);
  const [checkoutName, setCheckoutName] = useState("");
  const [checkoutEmail, setCheckoutEmail] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoaderModal(false); // Hide after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // Scroll to top when component mounts or navigates
  useEffect(() => {
    if (!pageLoaderModal) {
      // Find the position of the "Checking In" section relative to the top of the document
      const checkingInSection = document.getElementById("reservationinfo");
      const sectionPosition = checkingInSection.getBoundingClientRect().top;

      // Scroll the page to the position of the "Checking In" section
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
  }, []);

  // swiper
  const [setSwiperRef] = useState(null);

  const bookingSlug = useSelector((data) => {
    return data.checkIn.value;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(
          checkInActions.getBookingByCode(bookingSlug)
        );
        
        if (result?.payload?.status === HttpStatus.HTTP_OK) {
          setData(result?.payload?.result);
          setIsCheckedOut(result?.payload?.result?.is_checked_out);
          setIsWaiver(result?.payload?.result?.deposit_option);
          setIsDataEnabled(result?.payload?.result?.isDataEnabled);
          setIsTodayCheckedOut(result?.payload?.result?.isTodayCheckedOut);
          setListingCms(result?.payload?.result?.listing);          
          setCmsContent(result?.payload?.result?.listing_alert);
          setNeedToEmailName(result?.payload?.result?.needToEmailName);

          const listingSlug = result?.payload?.result?.listing?.slug;
          const formData = {id: listingSlug, booking_slug: bookingSlug};
          const listingCmsRes = await dispatch(
            onboardingActions.getListingCmsInfo(formData)
          );
          
          if (listingCmsRes?.payload?.status === HttpStatus.HTTP_OK) {
            setListingLocals(listingCmsRes?.payload?.result?.listing_local);
            setListingHost(listingCmsRes?.payload?.result?.listing_host);
            setItemsList(listingCmsRes?.payload?.result?.items);
            // setCmsContent(listingCmsRes?.payload?.result?.listing_alert);
          }
          getAgreementList();
          /*const cmsResult = await dispatch(commonActions.getCmsContents());
          if (cmsResult?.payload?.status === HttpStatus.HTTP_OK) {
            setCmsContent(cmsResult?.payload?.result);
          }*/
          getAdvertisement();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // Call the fetchData function inside useEffect
    fetchData();
  }, [isStateRefresh]);

  const markAsCheckedOut = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      booking_slug: bookingSlug,
    };
    try {
      const result = await dispatch(
        onboardingActions.markAsCheckedOut(formData)
      );
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        setIsCheckedOut(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred while submitting form:", error);
    }
  };

  const downloadReceipt = async (event) => {
    event.preventDefault();
    const eventType = parseInt(
      event.currentTarget.getAttribute("data-eventtype")
    );
    let queryString = "";
    if (eventType === 1) {
      queryString =
        "path=" + agreementItems?.stripe_receipt_url + "&file_name=Receipt";
    } else if (eventType === 2) {
      queryString =
        "path=" +
        agreementItems?.waiver_agreement_url +
        "&file_name=DamageWaiver";
    }
    try {
      let endPoint = "/download-file" + (queryString ? "?" + queryString : "");
      let filePath = process.env.REACT_APP_API_ENDPOINT + endPoint;
      window.location.href = filePath;
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred while submitting form:", error);
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));
  };

  const getAdvertisement = async (event) => {
    const formData = {
      listing_slug: listingSlug,
      booking_slug: bookingSlug,
    };
    const advertisementResult = await dispatch(
      earlyCheckin.getAdvertisement(formData)
    );

    if (advertisementResult?.payload?.status === HttpStatus.HTTP_OK) {
      const advertisementData = advertisementResult?.payload?.result;
      const adTimeInterval = advertisementData?.time_interval;
      // Convert the object into an array
      const adArray = Object.entries(adTimeInterval).map(([key, value]) => ({
        label: value,
        value: key,
      }));
      const intervalValue = adArray[0] ? adArray[0]?.value : "";
      setFirstIntervalValue(intervalValue);
      setSelectedTimeInterval(adArray[0]?.label);
      setTimeInterval(adArray);
      setIsAdShow(advertisementData?.isAdShow);
      setIsPurchased(advertisementData?.isPurchased);
      setIsCtaShow(advertisementData?.isCtaShow);
      setCardTitle(advertisementData?.card_title);
      setCardBody(advertisementData?.card_body);
      setNewCheckinTime(advertisementData?.checkin_time);
    }
  };

  const earlyLatePurchased = async (event) => {
    getAdvertisement();
    setIsStateRefresh(true);
  };

  const openEarlyCheckinPaymentModel = () => {
    setShowcheckinEarlyModal(true);
    if (needToEmailName) {
      setIsDesabled(true);
    }
  };

  const handleSelectChange = (e) => {
    setFirstIntervalValue(e?.value);
    setSelectedTimeInterval(e?.label);
    setIsDesabled(true);
    if (e?.value != "free") {
      if (needToEmailName) {
        const isEmailValid = validateEmail(checkoutEmail);
        if (checkoutName.length > 2 && isEmailValid) {
          setIsDesabled(false);
        }
      } else {
        setIsDesabled(false);
      }
      setIsFreeCheckout(false);
    } else {
      if (needToEmailName) {
        const isEmailValid = validateEmail(checkoutEmail);
        if (checkoutName.length > 2 && isEmailValid) {
          setIsDesabled(false);
        }
      } else {
        setIsDesabled(false);
      }
      setIsFreeCheckout(true);
    }
  };

  const createPaymentIntent = async (event) => {
    setLoader(true);
    setClientSecret(false);
    setIsIntentCreate(false);
    setIsIntentModelShow(false);
    let formData = {
      booking_slug: bookingSlug,
      intent_type: 3, // Conditional assignment
      needToEmailName: needToEmailName,
      checkout_name: checkoutName,
      checkout_email: checkoutEmail,
      time_interval: firstInterval
    };

    const result = await dispatch(paymentActions.createPaymentIntent(formData));
    const responses = result?.payload;
    if (responses?.status === HttpStatus.HTTP_OK) {
      const paymentRes = responses.result;
      setClientSecret(paymentRes?.client_secret);
      setCustomerId(paymentRes?.customer_id);
      setStripeId(paymentRes?.id);
      setPaymentSlug(paymentRes?.payment_slug);
      setLoader(false);
      setIsIntentCreate(true);
      setIsIntentModelShow(true);
      setNeedToEmailName(false); // When user already filed name and email not asked again
    } else {
      toast(responses?.message);
      setLoader(false);
      setIsIntentModelShow(false);
    }
  };

  const updatedCheckinCheckoutFreeTime = async () => {
    try {
      setLoader(true);
      const formData = {
        booking_slug: bookingSlug,
        time_interval: firstInterval,
        needToEmailName: needToEmailName,
        checkout_name: checkoutName,
        checkout_email: checkoutEmail,
      };
      const result = await dispatch(
        earlyCheckin.updatedCheckinCheckoutTime(formData)
      );
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        setIsStateRefresh(true);
        setShowcheckinEarlyModal(false);
        setLoader(false);
        setNeedToEmailName(false); // When user already filed name and email not asked again
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Unable to update booking checkin checkout time : ", error);
    }
  };
  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };
  const closeEarlyCheckinPaymentModel = () => {
    setShowcheckinEarlyModal(false);
    setIsIntentCreate(false);
    setIsIntentModelShow(false);
  };

  const handleCheckoutNameChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutName(inputValue);
    const isEmailValid = validateEmail(checkoutEmail);
    if (inputValue.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const handleCheckoutEmailChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutEmail(inputValue);
    const isEmailValid = validateEmail(inputValue);
    if (checkoutName.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

   // Callback function from child component
   const emailStatusChange = () => {
    setNeedToEmailName(false);
  }

  const getAgreementList = async (upsellPurchase = false) => {
    const agreementList = await dispatch(
      checkInActions.getAgrrements(bookingSlug)
    );

    if (agreementList?.payload?.status === HttpStatus.HTTP_OK) {
      setAgreementList(agreementList?.payload?.result);
      if(upsellPurchase){ // From upsells waiver purchased then only flag changed of isWaiver.
        setIsWaiver(1);
      }
    }
  }

  return (
    <section className="reservationnpage">
      {!pageLoaderModal && (
        <>
          <h3 className="text-center" id="reservationinfo">
            Your Arrival Instructions
          </h3>
          <div className="card-section">
            <div className="cardGreen">
              <div className="reserved">
                <div className="reservedDate">
                  <div className="checkedinDate">
                    <label>Check In</label>
                    <span className="dataLabel">
                      {isPurchased
                        ? Moment(newCheckinTime).format(global.dateTimeFormat)
                        : Moment(records?.checkin_datetime_format).format(
                            global.dateTimeFormat
                          )}
                      {/* {Moment(records?.checkin_datetime_format).format(
                    global.dateTimeFormat
                  )} */}
                </span>
                  </div>
                  <div className="checkedinDate">
                    <label>Check Out</label>
                    <span className="dataLabel">
                      {Moment(records?.checkout_datetime_format).format(
                        global.dateTimeFormat
                      )}
                    </span>
                  </div>
                </div>
                {isPurchased && (
                  <div className="early_checkin_button">
                    <Button className="">
                      <img src={EarlyCheckinImg} />
                      Early Check In
                    </Button>
                  </div>
                )}
                <figure>
                  {records?.listing?.image ? (
                    <img src={records?.listing?.image} alt="" />
                  ) : (
                    <Skeleton duration={1} height={300} />
                  )}
                   </figure>
                   </div>
                  
                   <div className="content">
                {/* {isDataEnabled && ( */}
                {records?.listing?.listing_address && (
                  <>
                    <div className="checkedinDate">
                      {/* <label>{records?.listing?.listing_address}</label> */}
                      <label
                        dangerouslySetInnerHTML={{
                          __html: records?.listing?.listing_address,
                        }}
                      />
                    </div>
                  </>
                )}
                {/* {isDataEnabled ? ( */}
                {records?.listing?.listing_map ? (
                  <>
                    <div className="checkedinDate">
                      <Link to={records?.listing?.listing_map} target="_blank">
                        {" "}
                        <img src={cornerupRight} alt="" />{" "}
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsContent?.address_alert,
                      }}
                    ></p>
                  </>
                )}
              </div>
              </div>

              {isAdShow && (
              <>
                <div className="cardYellow">
                  {/* <h2>{cardTitle}</h2> */}
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: cardTitle,
                    }}
                  ></h2>
                  {/* <p>{cardBody}</p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cardBody,
                    }}
                  ></p>

                  {isPurchased && (
                    <span className="dataLabel">
                      {Moment(newCheckinTime).format(global.dateTimeFormat)}
                    </span>
                  )}

                  {isPurchased == false && isCtaShow == true && (
                    <>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="fillbutton"
                          onClick={openEarlyCheckinPaymentModel}
                        >
                          Pay to check in early
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}


            <div className="cardBlue">
              <h2>Arrival Instructions</h2>
              {isDataEnabled ? (
                <>
                  <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.arrival_instructions,
                    }}
                  />
                  <hr className="mb-3" />
                  <h2>Wi-Fi Info</h2>
                  <div className="content">
                    <div className="checkedinDate">
                      <label>Network name</label>
                      <span className="dataLabel">{listingCms?.wifi_name}</span>
                    </div>
                    <div className="checkedinDate">
                      <label>Network password</label>
                      <span className="dataLabel">
                        {listingCms?.wifi_password}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.arrival_alert,
                    }}
                  ></p>
                </>
              )}
            </div>
        <div className="cardGreen2">
          <h2>Local Guides</h2>
          <div className="swiperSliderone">
            <Swiper
              onSwiper={setSwiperRef}
              spaceBetween={5}
              slidesPerView={1.2} // Set slidesPerView to 1.5
              navigation={false}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {listingLocals &&
                listingLocals.map((maps) => (
                  <SwiperSlide key={maps.slug}>
                    <div className="localMap">
                      <figure>
                        {maps?.image_path ? (
                          <img src={maps?.image_path} alt="" />
                        ) : (
                          <Skeleton duration={1} height={300} />
                        )}
                      </figure>
                      <div className="content">
                        <span className="dataLabel d-block w-100">
                          {maps?.title}{" "}
                        </span>
                        <span className="card-subtitle">
                          {maps?.description}{" "}
                        </span>
                        <div className="w-100 mt-3">
                          <Link to={maps?.redirect_url} target="_blank">
                            <Button className="fillbutton">
                              {maps?.cta_title}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
        {itemsList?.length > 0 && (
              <>
                <div className="cardGreen2 cardPink">
                  <h2>Upgrade your stay</h2>
                  <UpgradeItems 
                  itemsList={itemsList}
                  needToEmailName={needToEmailName}
                  cmsContent={cmsContent}
                  emailStatusChange={emailStatusChange}
                  getAgreementList={getAgreementList}
                  />
                </div>
              </>
            )}

            {listingCms?.house_manual && ( // It shows only if house manual is not empty
              <div className="cardBlue">
                <h2>House Manual</h2>
                <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.house_manual,
                    }}
                  />
              </div>
            )}

            <div className="cardOrange">
              <h2>Checkout Instructions</h2>
              {isDataEnabled ? (
                <>
                  <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.checkout_instructions,
                    }}
                  />
                </>
              ) : (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.checkout_alert,
                    }}
                  ></p>
                </>
              )}
              <div className="d-flex justify-content-center">
                {/* {isCheckedOut === 0 && isTodayCheckedOut && ( */}
                {/* <Button className="fillbutton" onClick={markAsCheckedOut} disabled={isLoading || isCheckedOut ? true : false}>
              {isLoading ? (<><SpinnerLoader /></>) : isCheckedOut ? "I Left" : ("I'm leaving now!")}
            </Button> */}

            {/* <Button
              className="fillbutton"
              onClick={markAsCheckedOut}
              disabled={isTodayCheckedOut ? isCheckedOut ? true : false : true}
            >
              {isLoading ? (
                <>
                  <SpinnerLoader />
                </>
              ) : isCheckedOut ? (
                "I Left"
              ) : (
                isTodayCheckedOut ? "I'm leaving now!" : isTodayCheckedOut && isCheckedOut ? "I Left " : "I'm leaving now!"
              )}
            </Button> */}
          </div>
        </div>
        <div className="cardBlank">
          <figure>
            <img src={listingHost?.host_logo} alt="" />
          </figure>
          <h2>Hosted by {listingHost?.host_name}</h2>
          {isWaiver === 1 && (
            <>
              {agreementItems?.stripe_receipt_url &&
                agreementItems?.stripe_receipt_url !== "" && (
                  <>
                    {/* <Button
                      className="outlinebutton w-100"
                      data-eventtype={1}
                      onClick={downloadReceipt}
                    >
                      Get Receipt
                    </Button> */}
                    <Link
                      to={agreementItems?.stripe_receipt_url}
                      target="_blank"
                      className="w-100"
                    >
                      <Button className="outlinebutton w-100">
                        Get <span className="text-uppercase">W</span>aiver{" "}
                        <span className="text-uppercase">R</span>eceipt
                      </Button>
                    </Link>
                  </>
                )}
            </>
          )}
          {isPurchased && (
            <>
              {agreementItems?.purchase_receipt_url &&
                agreementItems?.purchase_receipt_url !== "" && (
                  <>
                    <br />
                    <Link
                      to={agreementItems?.purchase_receipt_url}
                      target="_blank"
                      className="w-100"
                    >
                      <Button className="outlinebutton w-100">
                        Get <span className="text-uppercase">E</span>arly{" "}
                        <span className="text-uppercase">C</span>heck in{" "}
                        <span className="text-uppercase">R</span>eceipt
                      </Button>
                    </Link>
                  </>
                )}
            </>
          )}

          {isWaiver === 1 && (
            <>
              {agreementItems?.stripe_receipt_url &&
                agreementItems?.stripe_receipt_url !== "" &&
                agreementItems?.waiver_agreement_url &&
                agreementItems?.waiver_agreement_url !== "" && <br />}

              {agreementItems?.waiver_agreement_url &&
                agreementItems?.waiver_agreement_url !== "" && (
                  <Link
                    to={agreementItems?.waiver_agreement_url}
                    target="_blank"
                    className="w-100 mb-4"
                  >
                    <Button className="outlinebutton w-100">
                      Damage <span className="text-uppercase">W</span>aiver
                    </Button>
                  </Link>
                )}
            {/* {agreementItems?.rental_agreement_url &&
              agreementItems?.rental_agreement_url !== "" && (
                <Link
                  to={agreementItems?.rental_agreement_url}
                  target="_blank"
                  className="w-100"
                >
                  <Button className="outlinebutton w-100">
                    Rental <span className="text-uppercase">A</span>greement
                  </Button>
                </Link>
              )} */}
          </>
          )}
            
        </div>
        </div>
        </>
      )}
      {/* Item Purchase / Early Checkin Common Model */}
      <Modal
        show={showcheckinEarlyModal}
        // onHide={() => closeEarlyCheckinPaymentModel()}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="earlycheckin" 
      >
        <Modal.Header
          closeButton
          onClick={() => closeEarlyCheckinPaymentModel()}
        ></Modal.Header>
        <Modal.Body>
          <div>
            <h2
              className="mb-2"
              dangerouslySetInnerHTML={{
                __html: cardTitle,
              }}
            ></h2>
          </div>
          <h6
            className="early_checkin_popup_heading"
            dangerouslySetInnerHTML={{
              __html: cardBody,
            }}
          ></h6>
          <hr className="mb-2" />
          <div className="range custom_select_drop_box mb-3">
            {!isIntentModelShow ? (
              timeInterval && timeInterval.length > 0 ? (
                <React.Fragment>
                  <span className="dataLabel3" htmlFor="hours">
                    Do you want to arrive?
                  </span>
                  <Dropdown
                    options={timeInterval}
                    onChange={handleSelectChange}
                    value={firstInterval}
                    disabled={isIntentModelShow}
                  />
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              <React.Fragment>
                <span className="dataLabel3" htmlFor="hours">
                  You want to check in early at:
                </span>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    value={selectedTimeInterval}
                    disabled={true}
                    className="input-text2-modal"
                  />
                </Form.Group>
              </React.Fragment>
                )}
              </div>

          {needToEmailName && !isIntentModelShow && (
            <React.Fragment>
              <span className="dataLabel3" htmlFor="name">
                Name
              </span>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  id="name"
                  value={checkoutName}
                  onChange={handleCheckoutNameChange}
                  className="input-text2-modal"
                  required
                />
              </Form.Group>

              <span className="dataLabel3" htmlFor="email">
                Email
              </span>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  id="email"
                  value={checkoutEmail}
                  onChange={handleCheckoutEmailChange}
                  className="input-text2-modal"
                />
              </Form.Group>
            </React.Fragment>
          )}
          <hr className="mb-3 mt-3" />
          {!isIntentCreate && (
            <div className="d-flex justify-content-between mb-4">
              <Button
                className="outlinebutton cancel_btn"
                onClick={() => closeEarlyCheckinPaymentModel()}
              >
                Cancel
              </Button>
              {!isFreeCheckout ? (
                <Button
                  className="fillbutton"
                  onClick={createPaymentIntent}
                  disabled={isDesabled ? true : false}
                >
                  {loader ? <SpinnerLoader /> : " Checkout"}
                </Button>
              ) : (
                <Button
                  className="fillbutton"
                  onClick={updatedCheckinCheckoutFreeTime}
                  disabled={isDesabled ? true : false}
                >
                  {loader ? <SpinnerLoader /> : " Checkout"}
                </Button>
              )}
            </div>
          )}
          {isIntentCreate && (
            <Elements
              stripe={stripePromiseForEarlyCheckin}
              options={options}
            >
              <EarlyCheckoutForm
                closeEarlyCheckinPaymentModel={closeEarlyCheckinPaymentModel}
                selectedInterval={firstInterval}
                bookingSlug={bookingSlug}
                paymentSlug={paymentSlug}
                earlyLatePurchased={earlyLatePurchased}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
      {/* End of Modified Model*/}

     {/* Loader modalbox */}
     <Modal
        show={pageLoaderModal}
        // onHide={() => setshowIdCardModal(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="pageloaderBox"
      >
        <Modal.Body>
          <div className="loaderContent">
            <h1 className="spinnerText">
              <span className="spintextone d-block mb-3"></span>
              <span className="spintexttwo d-block"></span>
            </h1>
          </div>
        </Modal.Body>

        <div className="welcomebgvideomodal">
          <video autoPlay muted playsInline loop id="video">
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
        <div className="welcomebgvideomodalmobile">
          <video autoPlay muted playsInline loop id="video">
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      </Modal>
      {/* End Loader modalbox */}
    </section>
  );
}
export default Reservationinfo;
